<template>
  <v-container>
    <v-row justify="center" style="margin: 50px">
      <v-img max-height="310" max-width="310" src="../assets/NoAccess.png" />
    </v-row>
    <v-row align="center" justify="center">
      <mex-p content="Your registration period has expired!" fontSize="h3" />
    </v-row>
    <v-row align="center" justify="center">
      <mex-p content="Please contact a support a representative for further information." />
    </v-row>
  </v-container>
</template>

<script>
import SystemUsersService from '../services/systemUsers.service';

export default {
  name: 'Logout',
  created() {
    this.$store.commit('sysAuth/logout');
    SystemUsersService.systemUserLogout();
  },
  methods: {},
};
</script>
